import { useEffect } from "react";

const useOutsideClose = (onClickOutside, ref) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
        return;
      }
    }

    document.addEventListener &&
      document.addEventListener("mousedown", handleClickOutside, {
        passive: true,
      });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

export default useOutsideClose;
