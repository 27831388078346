// imports react and next
import { useEffect, useRef, useState } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";

//import components
const Logo = dynamic(() => import("@/common/UI/Logo"));
const LangSelector = dynamic(() => import("@/components/language/LanguageSelector"));

// imprts hooks
import { useIsMobile, withIsMobile } from "@/hooks/withIsMobile";
import useOutsideClose from "@/hooks/useOutsideClose";

// imports utilities
import { getSelectedLangData } from "@/utils/functions";

function Header(props) {
  const { siteConfig, settingsData, labelsList } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  const headerRef = useRef(null);
  const { width } = useIsMobile();

  const getURLSlug = (menu) => {
    if (menu?.slug) {
      return `/${menu?.slug}`;
    } else {
      return "/";
    }
  };

  useEffect(() => {
    if (width >= 992) {
      setMenuOpen(false);
    }
  }, [width]);

  useOutsideClose(() => {
    setMenuOpen(false);
  }, headerRef);

  return (
    <>
      <header
        id="mainMenu"
        className={`bg-transparent -top-[15px] left-0 right-0 z-40 fixed ${
          menuOpen ? "overflow-hidden" : "overflow-visible"
        }`}
        style={{
          transition: "0.4s ease",
          height: menuOpen ? "545px" : "138px",
        }}
        ref={headerRef}
      >
        <div className="absolute top-0 left-0 w-full h-full bg-white sm:opacity-90 -skew-y-1 -z-10" />
        <nav className="flex flex-col xl:flex-row justify-between px-6 pt-8 transition-all">
          <div className="flex items-center xl:gap-4 xxl:gap-8 w-full">
            <div onClick={() => setMenuOpen(false)}>
              <Logo regular={true} siteConfig={siteConfig} />
            </div>
            <div className="flex items-center w-full gap-4 justify-end" id="mainMenuItem">
              <div className="hidden xl:block w-full">
                <div className="flex items-center">
                  <ul className="relative w-full flex lg:flex-row items-center justify-between text-xs uppercase gap-4">
                    {siteConfig?.header?.length > 0 &&
                      siteConfig?.header?.map((menu, index) => {
                        return (
                          <li
                            key={index}
                            className="flex text-primary font-bold tracking-[1px] leading-4"
                          >
                            <Link
                              href={getURLSlug(menu)}
                              className="hover:text-danger cursor-pointer font-futuraBold xl:text-[12px] xxl:text-xs leading-[17px]"
                            >
                              {menu?.title}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>

              <div
                className="xl:hidden cursor-pointer flex items-center justify-center text-danger"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <iconify-icon
                  icon="akar-icons:three-line-horizontal"
                  height="30"
                  width="25"
                ></iconify-icon>
              </div>

              <div className="hidden md:block">
                <Link
                  href={`${
                    siteConfig?.bookingEngine?.[0]?.baseOutboundLinks
                  }&lang=${getSelectedLangData()}`}
                  target="_blank"
                  className="bg-success max-h-[52px] text-white flex items-center justify-center gap-4 p-4 font-bold uppercase"
                >
                  <h4 className="font-futuraBold text-xs min-w-max">{labelsList?.bookNow}</h4>
                  <div className="text-white">
                    <iconify-icon
                      icon="material-symbols:calendar-month-outline"
                      width="30"
                      height="30"
                    ></iconify-icon>
                  </div>
                </Link>
              </div>

              <LangSelector locales={settingsData?.locales} />
            </div>
          </div>
          {menuOpen && (
            <div className={`xl:hidden text-primary my-[42px]`}>
              <ul className="w-full flex flex-col items-start justify-start text-xs uppercase gap-6">
                {siteConfig?.header?.length > 0 &&
                  siteConfig?.header?.map((menu, index) => {
                    return (
                      <li
                        key={index}
                        className="flex text-primary font-bold tracking-[1px] leading-4"
                        onClick={() => setMenuOpen(false)}
                      >
                        <Link
                          href={getURLSlug(menu)}
                          className="inline-block hover:text-danger cursor-pointer"
                        >
                          <h4>{menu?.title}</h4>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
        </nav>
      </header>
    </>
  );
}

export default withIsMobile(Header);
